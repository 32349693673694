import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from 'lib/createRequestSaga';
import * as authAPI from 'lib/api/auth';

const CHANGE_LINK_TYPE = 'check/LINK_TYPE';
const CHANGE_LINK_DIC = 'check/LINK_DIC';
const [USER_CHECK, USER_CHECK_SUCCESS, USER_CHECK_FAILURE] = createRequestActionTypes('check/USER_INFO');

const initialState = {
    user: null,
    userStatus: 'none',
    linkType: '',
    linkDic: ''
};

export const changeLinkType = createAction(CHANGE_LINK_TYPE, linkType => linkType);
export const changeLinkDic = createAction(CHANGE_LINK_DIC, linkDic => linkDic); 
export const checkUserInfo = createAction(
    USER_CHECK,
    ({ userId, userEmail }) => ({
        userId, userEmail
    })
);

const checkUserInfoSaga = createRequestSaga(USER_CHECK, authAPI.checkUserInfo);

export function* authSaga() {
    yield takeLatest(USER_CHECK, checkUserInfoSaga);
};

const auth = handleActions({
    [CHANGE_LINK_TYPE]: (state, action) => ({ ...state, linkType: action.payload }),
    [CHANGE_LINK_DIC]: (state, action) => ({ ...state, linkDic: action.payload }),
    [USER_CHECK_SUCCESS]: (state, { payload: user }) => ({
        ...state,
        user: user.user,
        userStatus: user.status
    }),
    [USER_CHECK_FAILURE]: (state, { payload: user }) => ({
        ...state,
        user: null
    }),
}, initialState);

export default auth;