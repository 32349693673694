import React from 'react';
import { withRouter, Route } from 'react-router-dom';
//import { Modal } from 'antd';
import { Home, Search, Result } from 'pages';

import 'antd/dist/antd.css';
import './App.css';

const App = props => {
  //props.history.listen(() => Modal.destoryAll());

  return (
    <div className='App'>
      <Route exact path="/" component={Home}></Route>
      <Route path="/search" component={Search}></Route>
      {/*}
      <Route path={["/result/std/:id","/result/syn/:id","/result/ant/:id"]} component={Result}></Route>
      */}
      <Route path={["/result/:dic/:id"]} component={Result}></Route>
    </div>
  )
}

export default withRouter(App);