import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import loading from './loading';
import searchword from './searchword';
import auth, { authSaga } from './auth';
import searchlist, { searchListSaga }  from './searchlist';
import searchresult, { searchResultSaga } from './searchresult';

const rootReducer = combineReducers({
    loading,
    searchword,
    searchlist,
    searchresult,
    auth
});

export function* rootSaga() {
    yield all([searchListSaga(), searchResultSaga(), authSaga()]);
}

export default rootReducer;