import { createAction, handleActions } from 'redux-actions';

const CHANGE_DIC = 'search/CHANGE_DIC';
const CHANGE_WORD = 'search/CHANGE_WORD';
const CHANGE_PAGE = 'search/CHANGE_PAGE';

const initialState = {
    searchDic: 'syn',
    searchWord: '',
    activePage: 1
};

export const changeDic = createAction(CHANGE_DIC, searchDic => searchDic);    
export const changeWord = createAction(CHANGE_WORD, searchWord => searchWord);
export const changePage = createAction(CHANGE_PAGE, activePage => activePage);

const searchword = handleActions({
    [CHANGE_DIC]: (state, action) => ({ ...state, searchDic: action.payload }),
    [CHANGE_WORD]: (state, action) => ({ ...state, searchWord: action.payload }),
    [CHANGE_PAGE]: (state, action) => ({ ...state, activePage: action.payload })
}, initialState);   

export default searchword;