import client from './client';

export const findByWord = search => 
    client.post(
        '/api/search/findByWord', 
        { params: { 
            dic: search.searchDic,
            word: search.searchWord,
            pageSize: search.pageSize,
            pageNum: search.activePage
        }});

export const findByStdWordId = search =>
    client.post(
        '/api/search/findByStdWordId', 
        { params: { 
            wordId: search.searchWordId            
        }});

export const findBySynWordId = search =>
    client.post(
        '/api/search/findBySynWordId', 
        { params: { 
            userId: search.userId,
            wordId: search.searchWordId,
            showType: search.searchShowType
        }});

export const findByAntWordId = search =>
    client.post(
        '/api/search/findByAntWordId', 
        { params: {
            userId: search.userId,
            wordId: search.searchWordId,
            showType: search.searchShowType           
        }});
