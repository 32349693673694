import React from 'react';
import Loadable from 'react-loadable';

const Loading = () => {
    return (
        <div id="fuse-splash-screen">
            <div className="center">
                <div className="logo">
                    <img width="100" src="/assets/logo/Logo_30_C.png" alt="logo" />
                </div>
                <br />
                <div className="loading"></div>
            </div>
        </div>
    );
};

export const Home = Loadable({
    loader: () => import('./home'),
    loading: Loading,
});

export const Search = Loadable({
    loader: () => import('./search'),
    loading: Loading,
});

export const Result = Loadable({
    loader: () => import('./result'),
    loading: Loading,
});