import client from './client';

export const check = () => client.get('/api/validate');
export const logout = () => client.get('/api/logout');
export const checkUserInfo = user => 
    client.post(
        '/api/checkUserInfo',
        { params: {
            userId: user.userId,
            userEmail: user.userEmail
        }});