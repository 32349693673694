import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from 'lib/createRequestSaga';
import * as searchAPI from 'lib/api/search';

const CHANGE_SHOW_TYPE = 'search/CHANGE_SHOW_TYPE';
const [SYN_SEARCH_WORD_ID, SYN_SEARCH_WORD_ID_SUCCESS, SYN_SEARCH_WORD_ID_FAILURE] = createRequestActionTypes(
	'search/syn/SEARCH_WORD_ID'
);
const [ANT_SEARCH_WORD_ID, ANT_SEARCH_WORD_ID_SUCCESS, ANT_SEARCH_WORD_ID_FAILURE] = createRequestActionTypes(
	'search/ant/SEARCH_WORD_ID'
);
const [STD_SEARCH_WORD_ID, STD_SEARCH_WORD_ID_SUCCESS, STD_SEARCH_WORD_ID_FAILURE] = createRequestActionTypes(
	'search/std/SEARCH_WORD_ID'
);

const initialState = {
	searchShowType: '1',
	searchResultItem: null,
	searchResultData: [],
};

export const changeShowType = createAction(CHANGE_SHOW_TYPE, (searchShowType) => searchShowType);
export const findBySynWordId = createAction(SYN_SEARCH_WORD_ID, ({ userId, searchWordId, searchShowType }) => ({
	userId,
	searchWordId,
	searchShowType,
}));
export const findByAntWordId = createAction(ANT_SEARCH_WORD_ID, ({ userId, searchWordId, searchShowType }) => ({
	userId,
	searchWordId,
	searchShowType,
}));
export const findByStdWordId = createAction(STD_SEARCH_WORD_ID, ({ searchWordId }) => ({
	searchWordId,
}));

const findBySynWordIdSaga = createRequestSaga(SYN_SEARCH_WORD_ID, searchAPI.findBySynWordId);
const findByAntWordIdSaga = createRequestSaga(ANT_SEARCH_WORD_ID, searchAPI.findByAntWordId);
const findByStdWordIdSaga = createRequestSaga(STD_SEARCH_WORD_ID, searchAPI.findByStdWordId);

export function* searchResultSaga() {
	yield takeLatest(SYN_SEARCH_WORD_ID, findBySynWordIdSaga);
	yield takeLatest(ANT_SEARCH_WORD_ID, findByAntWordIdSaga);
	yield takeLatest(STD_SEARCH_WORD_ID, findByStdWordIdSaga);
}

const searchresult = handleActions(
	{
		[CHANGE_SHOW_TYPE]: (state, action) => ({ ...state, searchShowType: action.payload }),
		[SYN_SEARCH_WORD_ID_SUCCESS]: (state, { payload: searchlist }) => ({
			...state,
			searchResultData: searchlist,
		}),
		[ANT_SEARCH_WORD_ID_SUCCESS]: (state, { payload: searchlist }) => ({
			...state,
			searchResultData: searchlist,
		}),
		[STD_SEARCH_WORD_ID_SUCCESS]: (state, { payload: searchlist }) => ({
			...state,
			searchResultData: searchlist,
		}),
		[SYN_SEARCH_WORD_ID_FAILURE]: (state, { payload: error }) => ({
			...state,
			searchResultError: error,
		}),
		[ANT_SEARCH_WORD_ID_FAILURE]: (state, { payload: searchlist }) => ({
			...state,
			searchResultError: searchlist,
		}),
		[STD_SEARCH_WORD_ID_FAILURE]: (state, { payload: searchlist }) => ({
			...state,
			searchResultError: searchlist,
		}),
	},
	initialState
);

export default searchresult;
