import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from 'lib/createRequestSaga';
import * as searchAPI from 'lib/api/search';

const [SEARCH_WORD, SEARCH_WORD_SUCCESS] = createRequestActionTypes('search/SEARCH_WORD');

const initialState = {
    searchWordList: []    
};

export const findByWord = createAction(
    SEARCH_WORD,
    ({ searchDic, searchWord, pageSize, activePage }) => ({
        searchDic, searchWord, pageSize, activePage
    })
);

const findByWordSaga = createRequestSaga(SEARCH_WORD, searchAPI.findByWord);

export function* searchListSaga() {
    yield takeLatest(SEARCH_WORD, findByWordSaga);
};

const searchlist = handleActions({
    [SEARCH_WORD_SUCCESS]: (state, { payload: searchlist }) => ({
        ...state,
        searchWordList: searchlist
    })
}, initialState);

export default searchlist;